// style for error page
.error-page-wrapper {
    @include center-div-flex();
    min-height: 100vh;
    background-position: 0px 0px;
    overflow: hidden;
    inset: 0px;
    text-align: center;

    .error__contents {
        .error--404 {
            font-family: $manrope-font;
            font-size: 11.625rem;
            font-weight: $font-weight800;
            background-color: $moderate-blue;
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
            text-transform: uppercase;
            line-height: 14rem;
        }

        .oops--message {
            color: $dark-gray;
            font-size: 2rem;
            font-weight: $font-weight400;
            text-transform: uppercase;
            margin-top: 0px;
            margin-bottom: 0.4rem;
            letter-spacing: 3px;
            text-align: center;
        }

        .opps-sub-message {
            color: $dark-gray;
            font-size: 1.2rem;
            margin-bottom: 0.4rem;
        }

        .go-home-text {
            color: $dark-gray;
            font-size: 1.2rem;
            font-weight: $font-weight500;
            transition: all 3s ease-in-out;

            a {
                transition: all 3s ease-in-out;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}