// standard btn styles
.primary-btn {
  @include primary-btn(
    $white-text,
    $primary-btn-color,
    $moderate-blue,
    $white-text
  );
  @include center-div-flex();

  .icon-wrapper,
  .label-wrapper {
    display: inline-block;
    margin: 0 0.243rem;
  }

  .icon-wrapper {
    i {
      margin-top: unset;
    }
  }
}

.inactiveBtn {
  @include primary-btn($white-text, $lighter-gray, $lighter-gray, $white-text);

  &:hover {
    cursor: no-drop;
  }

  .fetching {
    animation: rotation 2s infinite cubic-bezier(0.1, 0.64, 0.58, 1);
    @keyframes rotation {
      50% {
        transform: rotate(360deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
}

.__next__btn__wrapper {
  display: flex;
  align-items: center;

  .__next__btn {
    @include primary-btn(
      $white-color,
      $primary-btn-color,
      $moderate-blue,
      $white-color
    );
    margin: 0 0.8rem;

    .icon--pos {
      position: relative;
      top: 0.15rem;
      margin-left: 0.5rem;
    }
  }

  .inactiveBtn {
    @include primary-btn(
      $white-text,
      $lighter-gray,
      $lighter-gray,
      $white-text
    );

    &:hover {
      cursor: no-drop;
    }

    .fetching {
      animation: rotation 2s infinite cubic-bezier(0.1, 0.64, 0.58, 1);
      @keyframes rotation {
        50% {
          transform: rotate(360deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.__btn__wrapper {
  display: flex;
  align-items: center;

  .back__btn {
    min-width: 80px;
    max-width: 80px;
    margin-right: 1rem;
    padding: 0.687rem 1rem;
    // padding: 0.2rem 0;
    color: $moderate-blue;
    font-weight: $font-weight600;
    background-color: $white-color;
    box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
      0px 2px 4px rgba(15, 86, 179, 0.2);

    &::before {
      background-color: $white-color;
      color: $moderate-blue;
    }
  }
}

.skip-area {
  width: 100px;
  font-weight: $font-weight700;
  text-align: right;
  color: $moderate-blue;
  padding: 0.657rem 1rem;
  cursor: pointer;
}

.small__btn {
  min-width: 51px;
  height: 100%;
  border-radius: $border-radius6px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5%;
  font-family: $manrope-font;
}

.small__btn__round {
  // min-width: 70px;
  min-width: 80px;
  height: 100%;
  border-radius: 18px;
  background-color: $very-soft-blue;
  color: $moderate-blue;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5%;
  font-family: $manrope-font;
  cursor: default;
}

@mixin small-rounded-btnstyles($height, $width, $bgColor, $color) {
  @include center-div-flex();
  height: $height;
  width: $width;
  border-radius: 50%;
  background-color: $bgColor;
  color: $color;
  &:hover {
    cursor: pointer;
  }
}
