// Slick slider overrrides
.slick-dots {
    li.slick-active {
        button {
            &::before {
                color: $white-text !important;
            }
        }
    }
} 

.slick-dots li button:before {
    font-size: 9px !important;
    color: $white-text !important;
}

.slick-slide {
    > div {
        transition: transform .3s cubic-bezier(.4, 0, .2, 1);
    }
}