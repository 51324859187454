// style of primary buttons
@mixin primary-btn($color, $bgcolor, $hovercolor, $hovertextcolor) {
  max-width: 100%;
  min-width: 106.33px;
  color: $color;
  background-color: $bgcolor;
  position: relative;
  text-align: center;
  padding: 0.58rem 1rem;
  border-radius: $border-radius8px;
  display: inline-block;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  text-transform: capitalize;
  z-index: 2;
  border: 0;
  overflow: hidden;
  &::before {
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: "";
    background-color: $hovercolor;
    border-radius: $border-radius8px;
    z-index: -1;
  }
  &:hover {
    cursor: pointer;
    color: $hovertextcolor;
    background-color: $hovercolor;
    &:before {
      -webkit-transition: 0.5s all ease;
      transition: 0.5s all ease;
      left: 0;
      right: 0;
      opacity: 1;
    }
  }
  &:focus {
    border: 1px solid $hovercolor;
    &::before {
      -webkit-transition: 0.5s all ease;
      transition: 0.5s all ease;
      left: 0;
      right: 0;
      opacity: 1;
    }
  }
  &:active {
    background-color: $hovercolor;
    &:before {
      -webkit-transition: 0.5s all ease;
      transition: 0.5s all ease;
      left: 0;
      right: 0;
      opacity: 1;
    }
  }
}

@mixin rippleEffect($bgColor) {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: $bgColor;
}

span.ripple {
  @include rippleEffect(rgba(255, 255, 255, 0.7));
  // background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
