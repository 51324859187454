.backdrop {
  z-index: 1250;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  .custommodal {
    // width: clamp(50%, 1300px, 90%);
    // height: min(50%, 300px);
    background-color: white;
    // margin: auto;
    // padding: 0 2rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    .alert__card {
      max-width: 500px;
      z-index: 1350;
      .alert__icon__wrapper {
        text-align: center;
        font-size: 80px;
        color: red;
      }
      .alert__message {
        @include normal-text();
        text-align: center;
      }
      .alert__btn__wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 1rem;
        .alert__btn__left {
          background-color: #99e9b9;
          border-radius: 8px;
          color: green;
        }
        .alert__btn__right {
          background-color: $pale-red;
          border-radius: 8px;
          color: red;
        }
      }
    }
  }
}

.alert__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
