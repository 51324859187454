.slide-pane__content {
  padding: 0px;
  .sliding-pane-header {
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    gap: 1rem;
    background-color: $light-soft-blue;
    @include normal-text();
    .slider-close-btn {
      font-weight: bolder;
      color: $dark-black;
      font-size: 20px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .compitior-slider-body {
    padding: 15px;
    .sources_add_selected_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .selected--number-container {
        @include normal-text();
        color: $moderate-blue;
      }
    }

    .checkbox-input-btn {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      .zip-city-hotel-wrapper {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
      .input__text__btn__wrapper {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        .form-input-text {
          border: 1px solid $border-line;
          border-radius: 8px;
          text-align: center;
          ::-webkit-input-placeholder {
            text-align: center;
          }

          :-moz-placeholder {
            text-align: center;
          }
        }
      }
      .selected_hotel_add_wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
        .selected_hotel_number {
          @include normal-text();
          color: $moderate-blue;
        }
      }
    }
  }
}
.custom-tooltip-label {
  @include normal-text();
  color: $moderate-blue;
}
