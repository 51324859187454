.standardize_room_wrapper {
  display: flex;
  flex-direction: column;
  .add_standard_room_btn {
    width: 15%;
    align-self: flex-end;
  }
  .table-bordered {
    .standardize_room_table_head {
      @include normal-text();
      color: $bright-blue;
      text-align: center;
    }
    .standardize_room_table_body_row {
      text-align: center;
      @include sub-text();
      .standardize_room_edit_button {
        background-color: #90ffff;
      }
    }
  }
  .pagination__wrapper {
    margin-top: 1.5rem;
    .paginationBttns {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .previous {
        .previousbtn {
          color: blue !important;
          font-size: 20px;
        }
      }
      .next {
        .nextbtn {
          color: blue !important;
          font-size: 20px;
        }
      }
      .paginationdisabled {
        .previousbtn {
          display: none;
        }
      }

      .paginationactive {
        height: 25px;
        width: 25px;
        font-size: 20px;
        border-radius: 50%;
        background-color: lightskyblue;
        @include center-div-flex();
      }
    }
  }
}
