.breadcrumbs__wrapper {
  display: flex;
  align-items: center;
  .breadcrumbs__labels {
    @include normal-text;
    color: $moderate-blue;
  }
  .breadcrumbs__labels_second {
    @include normal-text;
  }
}
