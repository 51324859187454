.dashboard_modal__card {
  position: relative;
  width: 100%;
  // min-width: fit-content;
  // margin: auto;

  .dashboard_modal-header {
    // position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $white-color;
    // height: 40px;
    padding: 10px;
    border-radius: $border-radius12px;
    box-shadow: 0px 3px 3px #1d1fde;
    margin-bottom: 10px;
    position: sticky;
    top: 0px;
    z-index: 3;
    // padding-right: 10%;
    .dashboard_modal_filter_wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 1rem;
      position: sticky;
      left: 0px;
      .dashboard-lable-selectfield {
        min-width: 200px;
      }
      .calender-and-sliding-btn {
        .date-and-btn-show {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          @include normal-text();
          color: $slight-moderate-blue;
          .clander-next-prev-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            .clander-next-prev-btn-left-rigth {
              background-color: $slight-moderate-blue;
              @include center-div-flex();
              height: 30px;
              width: 30px;
              border-radius: 50%;
              color: $white-color;
              font-weight: bold;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .dashboard_btn-close {
      color: $slight-moderate-blue !important;
      font-weight: bold;
      font-size: 18px;
      padding: 5px;
      border-radius: $border-radius8px;
      &:hover {
        cursor: pointer;
        border: 1px solid red;
        color: red;
      }
    }
  }

  .modal-body {
    padding: 0px !important;
    margin: 0.5rem 0rem;
    overflow: auto;
    height: 700px;
    thead th {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
    }

    .table-bordered {
      box-shadow: 0px 2px 4px rgba(97, 97, 97, 0.18),
        0px 4px 8px rgba(97, 97, 97, 0.18);
      border-radius: 12px;
      border-color: #e0e0e0;
      // position: relative;
      .table-head {
        // position: sticky;
        // top: 40px;
        background-color: white;
        .table-row {
          .th-col {
            padding: 15px;
            text-align: center;
            min-width: 200px;
          }
          .competitors {
            background-color: #c5dcff;

            color: #223189;
            font-weight: 700;
            font-size: 16px;
          }
        }
      }
      .per-ota-small-cards {
        background: #ececec;
        border-radius: 4px;
        padding: 5px;
        min-width: 220px;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 0 10px 0;
        .occupancy-rate-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 0.3rem;
          .occupancy-wrapper {
            font-weight: 700;
            background: #ffe3ba;
            border-radius: 7px;
            padding: 5px;
            width: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .netRate-wrapper {
            font-weight: 700;
            background: #c5f2c7;
            border-radius: 7px;
            // width: 100px;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            padding: 5px;
          }
          .rate-warraper {
            font-weight: 700;
            background: #c5f2c7;
            border-radius: 7px;
            // width: 100px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            .netRate {
              text-decoration: line-through;
            }
          }
        }
      }
      .soldOut {
        color: red;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .add-btn {
      background-color: #0f56b3;
      border-radius: 8px;
      width: 110px;
      margin-left: 2rem;
      color: #ffffff !important;
    }
    .selected_label {
      font-family: "Manrope", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #606f89;
      padding-left: 7px;
    }
    .selected_label_ota {
      font-family: "Manrope", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #606f89;
      margin-left: 50px;
    }

    .modal.right .modal-dialog {
      position: fixed;
      margin: auto;
      width: auto;
      height: 100%;
      transform: translate3d(0%, 0, 0);
    }

    .modal.right .modal-content {
      height: auto;
      overflow-y: auto;
    }

    .modal.right .modal-body {
      padding: 15px 15px 80px;
    }

    .modal.right.fade .modal-dialog {
      right: 0px;
      transition: opacity 0.3s linear, right 0.3s ease-out;
    }
    .modal.right.fade.in .modal-dialog {
      right: 0;
    }

    .hotel-name-data {
      color: #2e3b52;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
    .slide-pane__header {
      background-color: #c5dcff;
    }
    .slide-pane__title {
      color: #1672ec;
      font-family: "Manrope", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
    }
    .some-custom-clas {
      width: 30% !important;
    }
    .slide-pane__close svg {
      font-size: 30px;
      width: 30px;
      height: 30px;
      color: #1672ec;
    }
    .display_competitor_day_price {
      display: block;
      // color: #2e3b52;
      // font-family: "Inter", sans-serif;
      // font-style: normal;
      // font-weight: 500 !important;
      // font-size: 14px;
      // line-height: 17px;
    }
    .success-message {
      background-color: #c5f2c7;
      max-width: 30%;
      height: 56px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .success-title {
      font-family: "Manrope", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 150%;
      color: #000000;
    }
    .success-close {
      color: #2ab930;
      font-size: 20px;
    }
    .success-mark {
      color: #2ab930;
      font-size: 20px;
    }
    .success-ota-message {
      background-color: #c5f2c7;
      max-width: 50%;
      height: 56px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    // .slide-pane__content {
    //   overflow-x: inherit !important;
    // }
    .footer-container {
      padding-top: 30px;
    }
    .ota_footer_container {
      padding-top: 30px;
    }
    // .text-center {
    //   border-style: solid !important;
    // }

    // New Design table styles

    .modal-body {
      .modal-body-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .modal-body-col {
        flex-basis: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border: 0.5px solid #a6a6a6;
      }

      .compdata-hotel-name {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
      .compitator-data-mapping-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
      }
    }
    @media screen and (min-width: 1300px) {
      .modal-body-col {
        flex: 1;
      }
    }
    @media screen and (min-width: 800px) {
      .modal-body-col {
        flex: 1;
      }
    }
  }

  .stickyLeft {
    background-color: white !important;
    position: sticky;
    left: 0;
    z-index: 1;
    // top: 110px;
  }
}
