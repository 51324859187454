// spinner loader css

.div__loader__wrapper {
  .login--loader {
    display: inline-block;
    width: 46px;
    height: 46px;
    border: 4px solid $pale-blue;
    border-radius: 50%;
    border-top-color: $moderate-blue;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}


// Data Loader
.div__data__loader__wrapper {
  .data--loader {
    display: inline-block;
    width: 29px;
    height: 29px;
    border: 2px solid $pale-blue;
    border-radius: 50%;
    border-top-color: $moderate-blue;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}