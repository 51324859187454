.compset__breadcrumbs__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selected__delete__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  @include normal-text();
  background-color: $very-soft-blue;
  color: $bright-blue;
  border-radius: $border-radius8px;
  .remove__btn {
    background-color: $strong-red;
    color: $white-color;
    padding: 5px;
    border-radius: $border-radius8px;
    &:hover {
      cursor: pointer;
    }
  }
}
.compset_table_card {
  margin: 1rem 0rem;
  .table-bordered {
    .compset_table_head {
      @include normal-text();
      color: $bright-blue;
      text-align: center;
    }
    .compset_table_body_row {
      text-align: center;
      @include sub-text();
    }
  }
  .pagination__wrapper {
    margin-top: 1.5rem;
    .paginationBttns {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .previous {
        .previousbtn {
          color: blue !important;
          font-size: 20px;
        }
      }
      .next {
        .nextbtn {
          color: blue !important;
          font-size: 20px;
        }
      }
      .paginationdisabled {
        .previousbtn {
          display: none;
        }
      }

      .paginationactive {
        height: 25px;
        width: 25px;
        font-size: 20px;
        border-radius: 50%;
        background-color: lightskyblue;
        @include center-div-flex();
      }
    }
  }
}

// Add Compset Slider Css
.add_compset_table_wrapper {
  .comp-ota-table {
    .comp-ota-table-head {
      background-color: skyblue;
      text-align: center;
      @include normal-text();
    }
    .add-comp-table-body-tr {
      .add-comp-table-body-td {
        .add-comp-input-checkbox {
          width: 20px;
          height: 20px;

          -webkit-appearance: block;
          &:hover {
            cursor: pointer;
          }
        }
        padding: 10px;
        text-align: center;
        @include sub-text();
      }
    }
  }
  .pagination__wrapper {
    margin-top: 1.5rem;
    .paginationBttns {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .previous {
        .previousbtn {
          color: blue !important;
          font-size: 20px;
        }
      }
      .next {
        .nextbtn {
          color: blue !important;
          font-size: 20px;
        }
      }
      .paginationdisabled {
        .previousbtn {
          display: none;
        }
      }

      .paginationactive {
        height: 25px;
        width: 25px;
        font-size: 20px;
        border-radius: 50%;
        background-color: lightskyblue;
        @include center-div-flex();
      }
    }
  }
}
.no_data_found {
  text-align: center;
  margin-top: 10rem;
  @include normal-text();
}
