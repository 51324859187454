// horizontally and vertically center a div using flex
@mixin center-div-flex() {
  display: flex;
  justify-content: center;
  align-items: center;
}
  
@mixin center-div-position() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}