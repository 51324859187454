.roomtype__breadcrumbs__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .roomtype_tab_div {
    .roomtype_tab_ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // padding-bottom: 10px;
      // border-bottom: 1px solid $border-line;
      .roomtype_tab_li {
        width: 200px;
        text-align: center;
        border: 1px solid $border-line;
        .roomtype_tab_btn {
          margin: 0px;
          padding: 10px;
          width: 100%;
        }
        .roomtype_tab_active_btn {
          // background-color: #dedede;

          border-top: 1px solid $moderate-blue;
          border-right: 1px solid $moderate-blue;
          border-left: 1px solid $moderate-blue;
          border-bottom: 3px solid $moderate-blue;

          // border-radius: $border-radius8px;
          // border-bottom-left-radius: 8px;
          // border-bottom-right-radius: 8px;
          background-color: $very-soft-blue;
          color: $moderate-blue;
        }
      }
      .roomtype_tab_active_li {
        border: 0px;
        box-shadow: 0px 15px 10px -15px #111;
      }
    }
  }
}

.roomtype_table_wrapper {
  .roomtype_table_card {
    margin-bottom: 20px;
    min-height: 500px;

    .roomtype_tab_content {
      margin: 1rem 0rem;
      .room_mapping_wrapper {
        display: flex;
        flex-direction: column;
        .room_mapping_dropdown {
          width: 25%;
          align-self: flex-end;
        }
        .table-bordered {
          .roomtype_table_head {
            @include normal-text();
            color: $bright-blue;
            text-align: center;
            .comp_th {
              background-color: #c5dcff;
            }
          }
          .roomtype_table_body_row {
            text-align: center;
            @include sub-text();
            .MuiFormControl-root {
              max-width: 140px;
              // min-width: 140px;
            }
          }
        }
        .roomtype_save_btn_wrapper {
          width: 15%;
        }
        .pagination__wrapper {
          margin-top: 1.5rem;
          .paginationBttns {
            display: flex;
            justify-content: space-around;
            align-items: center;
            .previous {
              .previousbtn {
                color: blue !important;
                font-size: 20px;
              }
            }
            .next {
              .nextbtn {
                color: blue !important;
                font-size: 20px;
              }
            }
            .paginationdisabled {
              .previousbtn {
                display: none;
              }
            }

            .paginationactive {
              height: 25px;
              width: 25px;
              font-size: 20px;
              border-radius: 50%;
              background-color: lightskyblue;
              @include center-div-flex();
            }
          }
        }
      }
    }
  }
}
