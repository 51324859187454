.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 60%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid rgb(46, 16, 242);
  /* Orange */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 6px;
  animation: spinloader 2s linear infinite;
}

.loader img {
  height: 45px;
  width: 45px;
  filter: drop-shadow(2px 4px 6px black);
  animation: spinlogo 2s linear infinite;
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinlogo {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}