.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1230;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1250;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  outline: 0;
  .modal__card {
    opacity: 1;
  }
  .modal {
    z-index: 1300;
    background-color: white;
    position: relative;
    margin: 1.75rem auto;
    border-radius: 3px;
    max-width: 500px;
    padding: 2rem;
  }
  // .modal-header {
  //   display: flex;
  //   justify-content: flex-end;
  // }

  // .modal-close-button {
  //   font-size: 1.4rem;
  //   font-weight: 700;
  //   line-height: 1;
  //   color: #000;
  //   opacity: 0.3;
  //   cursor: pointer;
  //   border: none;
  // }
}

button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
}

.button-default {
  background: #247ba0;
  color: #fff;
}
