// Style for login page
.div-page-wrapper {
  min-height: 100vh;
  background-color: $bg-color;

  .div__wrapper {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;

    // New Stylings Start
    .div__wrapper__lottie {
      width: 40%;
      height: 100vh;
      background-color: $moderate-blue;
      background-image: linear-gradient($moderate-blue, $dark-black);
    }
    .log__in_form__wrapper {
      width: 60%;
      height: 100vh;
      @include center-div-flex();

      .div__inputs {
        min-width: 380px;

        .div--head--label {
          @include main-text();
          margin-bottom: 0.5rem;
        }

        .div--helper--text {
          display: flex;
          align-items: center;
          gap: 1rem;
          justify-content: flex-start;
          @include normal-text();
          font-weight: $font-weight400;
          color: $dark-bright-gray;
        }

        .dev--helper--signin-sign-up {
          color: $moderate-blue;
          font-weight: $font-weight600;

          &:hover {
            cursor: pointer;
          }
        }

        .or__wrapper {
          position: relative;
          margin: 2rem 0rem;

          .or--text {
            @include sub-text();
            position: absolute;
            top: -0.67rem;
            left: 50%;
            text-align: center;
            transform: translate(-50%, 0%);
            background-color: $bg-color;
          }
        }

        .div__inputs__wrapp {
          margin: 2rem 0rem;

          .div--email--input {
            width: 100%;
          }

          .div--password--input {
            width: 100%;
          }

          .__btn__wrapper {
            width: 100%;

            .login--btn {
              min-width: 100%;
              max-width: 100%;
            }

            .reset--btn {
              @extend .login--btn;
            }
          }

          .__back__btn {
            position: relative;
            width: auto;
            margin-top: 1rem;

            .goback--btn {
              @include primary-btn(
                $dark-gray,
                $white-color,
                $white-color,
                $dark-gray
              );
              position: absolute;
              top: 0;
              right: 0;
              min-width: 70px;
              max-width: 70px;
              font-weight: $font-weight500;
              text-align: right;
              padding: 0px;

              &:hover {
                box-shadow: none;
                color: $moderate-blue;
              }
            }
          }

          .forgot__passowrd__wrapper {
            position: relative;
            margin: 2rem 0rem;

            .forgot--text {
              @include sub-text();
              position: absolute;
              top: -0.67rem;
              left: 50%;
              text-align: center;
              transform: translate(-50%, 0%);
              background-color: $bg-color;
            }
          }

          .reset__btn__wrapper {
            .reset--btn {
              @include primary-btn(
                $moderate-blue,
                $white-color,
                $moderate-blue,
                $white-text
              );
              min-width: 100%;
              border: 1px solid $very-light-gray;
              font-weight: $font-weight400;

              &:hover {
                border: 1px solid transparent;
              }
            }
          }
        }
      }
    }
    // New Stylings End

    .div__input__wrapper__left {
      flex-basis: 28.99%;
      flex-grow: 0;
      max-width: 28.99%;
    }

    .div__left__side__box {
      box-sizing: border-box;
      margin: 0px;
      flex-direction: row;
      background-color: $moderate-blue;
      position: relative;
      align-self: stretch;

      .logo__wrapper {
        position: relative;
        width: 45px;
        height: 45px;
        margin: 1rem 2.5rem;
        z-index: 12;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxNyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEzNjVfMTIyODcpIj4KPHBhdGggZD0iTTE1LjM4OTkgNy4xMjI4OEMxMi45ODQxIDQuMzQ1NDcgOC42MTExNyA1LjA4OTY2IDguNjExMTcgNS4wODk2NkM2LjU1MDk3IC0wLjkwMzY5OCAzLjUyMDQ3IDAuMDUzMTEyNyAzLjUyMDQ3IDAuMDUzMTEyN0MyLjAzMTgxIDAuMjI1ODcgMS4xOTQ0NCAxLjcyNzUzIDEuMTk0NDQgMS43Mjc1M0MtMS44ODkyMiA4LjY3NzcgMS45NTIwNiAxNS45NDY4IDEuOTUyMDYgMTUuOTQ2OEMyLjIwNDYgMTYuNjkxIDIuNTEwMzEgMTcuNDA4NiAyLjg4MjQ4IDE4LjA4NjNDNC4yOTEzOSAyMC42NTExIDYuNDg0NTEgMTkuODkzNiA2LjQ4NDUxIDE5Ljg5MzZDOS4yNDkxNyAxOS4zODg3IDguODc3IDE5LjM0ODggMTAuODA0MyAxOC44MDM5QzEwLjkyMzkgMTguNzY0MSAxMS44ODA5IDE4LjM3ODcgMTIuMjkzIDE4LjIwNTlDMTMuNDIyNyAxNy43Mjc1IDE0LjQ4NjEgMTYuNzg0IDE0LjQ4NjEgMTYuNzg0QzE5LjU2MzUgMTEuNjAxMyAxNS4zODk5IDcuMTIyODggMTUuMzg5OSA3LjEyMjg4Wk0xMS4xNjMyIDEzLjYwNzlDMTAuMDMzNCAxMy40NjE4IDkuMjM1ODggMTIuNDI1MiA5LjM4MjA4IDExLjI4MjNDOS41MjgyOSAxMC4xNTI4IDEwLjU2NSA5LjM1NTQ0IDExLjcwODEgOS41MDE2MkMxMi44Mzc5IDkuNjQ3OCAxMy42MzU0IDEwLjY4NDMgMTMuNDg5MiAxMS44MjcyQzEzLjMyOTcgMTIuOTcwMSAxMi4yOTMgMTMuNzY3NCAxMS4xNjMyIDEzLjYwNzlaIiBmaWxsPSIjNTI2OUNFIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMTM2NV8xMjI4NyI+CjxyZWN0IHdpZHRoPSIxNyIgaGVpZ2h0PSIyMCIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K");
        background-color: $white-color;
        @include background-image-property();
        background-size: 26px 26px;
        border-radius: $border-radius12px;
      }

      .div__user__testimonials__wrapper {
        min-height: 100vh;
        background: $moderate-blue;
        position: absolute;
        background-position: 0px 0px;
        overflow: visible;
        margin: 0px 0px 0px auto;
        inset: 0px;
        z-index: 10;

        // opacity: 0.9;
        .user__testimonial_image {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: bottom right;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(
              0deg,
              rgba(75, 65, 169, 0.2),
              rgba(75, 65, 169, 0.2)
            );
          }

          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(
              181.52deg,
              rgba(0, 0, 0, 0) 12.87%,
              #020202 98.7%
            );
          }

          .user__testimonial__message__wrapper {
            position: absolute;
            left: 7.875rem;
            bottom: 3rem;
            width: 100%;
            max-width: 380px;
            padding: 1.3rem 1.5rem;
            background-color: $white-color;
            border-radius: $border-radius12px;
            box-shadow: 0px 12px 24px 6px rgba(97, 97, 97, 0.08),
              0px 24px 48px 6px rgba(97, 97, 97, 0.08);
            z-index: 13;

            .testimonial__icon {
              position: absolute;
              top: -1.5rem;
              width: 38px;
              height: 38px;
              color: $white-color;
              border-radius: $border-radius4px;
              background-color: $moderate-blue;
              @include center-div-flex();
            }

            .user__testimonial__text {
              @include normal-text();
              font-weight: $font-weight500;
            }

            .user__name {
              @include normal-text();
              color: $moderate-blue;
              padding-top: 0.3rem;
            }

            .user__designation {
              @include sub-text();
              font-size: $font-size80;
              font-weight: $font-weight600;
            }
          }
        }
      }
    }

    .div__input__wrapper__right {
      display: block;
      flex-basis: 71.01%;
      flex-grow: 0;
      max-width: 71.01%;
    }

    .div__right__side__box {
      box-sizing: border-box;
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      margin: 24px 0px;
      padding-left: 8.438rem;
    }
  }
}
