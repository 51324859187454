/* ----------------------------------------------------------------------------------------------------

SCSS Form Reset Helpers - Forked from: https://gist.github.com/anthonyshort/552543

Intended usage:
- MIXINS: for very specific use cases, when you dont want to reset absolutly all the forms, very verbose output.
- PLACEHOLDER SELECTORS: use as extending classes. Less verbose, more generic overrides.

A couple of things to watch out for:

- IE8: If a text input doesn't have padding on all sides or none the text won't be centered.
- The default border sizes on text inputs in all UAs seem to be slightly different. You're better off using custom borders.
- You NEED to set the font-size and family on all form elements
- Search inputs need to have their appearance reset and the box-sizing set to content-box to match other UAs
- You can style the upload button in webkit using ::-webkit-file-upload-button
- ::-webkit-file-upload-button selectors can't be used in the same selector as normal ones. FF and IE freak out.
- IE: You don't need to fake inline-block with labels and form controls in IE. They function as inline-block.
- By turning off ::-webkit-search-decoration, it removes the extra whitespace on the left on search inputs

----------------------------------------------------------------------------------------------------*/

@mixin form-reset-general {
    display: inline-block;
    margin: 0;
    border: 0;
    padding: 0;
    width: auto;
    vertical-align: middle; // IE6,IE7
    white-space: normal;
    line-height: inherit;
    background: none;
  
    /* Browsers have different default form fonts */
    color: inherit;
    font-size: inherit;
    font-family: inherit;
}
  
%form-reset-general {
    @include form-reset-general;
}
  
@mixin form-reset-special-box-sizing {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
  
%form-reset-special-box-sizing {
    @include form-reset-special-box-sizing;
}
  
@mixin form-reset-input-specific {
    &:focus {
      outline: 0;
    }
  
    &[type=checkbox],
    &[type=radio] {
      width: 13px;
      height: 13px;
    }
  
    /* Make webkit render the search input like a normal text field */
    &[type=search] {
      -webkit-appearance: textfield;
      -webkit-box-sizing: content-box;
}
  
/* Turn off the recent search for webkit. It adds about 15px padding on the left */
@at-root {
      ::-webkit-search-decoration {
        display: none;
      }
    }
  
    /* Fix IE7 display bug */
    &[type="reset"],
    &[type="button"],
    &[type="submit"] {
      overflow: visible;
    }
}
  
@mixin form-reset-input {
    @include form-reset-general;
    @include form-reset-input-specific;
  
    /* These elements are usually rendered a certain way by the browser */
    &[type=reset],
    &[type=button],
    &[type=submit],
    &[type=checkbox],
    &[type=radio] {
      @include form-reset-special-box-sizing;
    }
}
  
%form-reset-input {
    @extend %form-reset-general;
    @include form-reset-input-specific;
  
    /* These elements are usually rendered a certain way by the browser */
    &[type=reset],
    &[type=button],
    &[type=submit],
    &[type=checkbox],
    &[type=radio] {
      @extend %form-reset-special-box-sizing;
    }
}
  
@mixin form-reset-label {
    @include form-reset-general;
}
  
  %form-reset-label {
    @extend %form-reset-general;
}
  
@mixin form-reset-select-specific {
    /* Move the label to the top */
    &[multiple] {
      vertical-align: top;
    }
}
  
@mixin form-reset-select {
    @include form-reset-general;
    @include form-reset-special-box-sizing;
    @include form-reset-select-specific;
}
  
%form-reset-select {
    @extend %form-reset-general;
    @extend %form-reset-special-box-sizing;
    @include form-reset-select-specific;
}
  
@mixin form-reset-button-specific {
    background: none;
    border: 0;
    outline: none;
  
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
    &:hover,
    &:active,
    &:focus {
      outline: 0;
    }
  
    /* IE8 and FF freak out if this rule is within another selector */
    @at-root {
      ::-webkit-file-upload-button {
        padding: 0;
        border: 0;
        background: none;
      }
    }
}
  
@mixin form-reset-button {
    @include form-reset-general;
    @include form-reset-button-specific;
}
  
%form-reset-button {
    @extend %form-reset-general;
    @include form-reset-button-specific;
}
  
@mixin form-reset-textarea-specific {
    /* Move the label to the top */
    vertical-align: top;
  
    /* Turn off scroll bars in IE unless needed */
    overflow: auto;
}
  
@mixin form-reset-textarea {
    @include form-reset-general;
    @include form-reset-textarea-specific;
}
  
%form-reset-textarea {
    @extend %form-reset-general;
    @include form-reset-textarea-specific;
}