.dashboard_breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .dashboard-lable-fetch-date {
    @include normal-text();
    color: $moderate-blue;
  }
}
.dashboard-lable-tabs {
  @include center-div-flex();
  justify-content: space-between;
  .dashboard_filter_wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    .dashboard-lable-selectfield {
      min-width: 200px;
    }
  }

  .dashboard__month_change__btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .dashboard__month_change__btn__icon {
      //   color: $moderate-blue;
      //   font-size: 30px;
      //   &:hover {
      //     cursor: pointer;
      //   }
      @include small-rounded-btnstyles(
        40px,
        40px,
        $moderate-blue,
        $white-color
      );
    }
    .dashboard__month_change__btn_month-name {
      @include normal-text();
      color: $moderate-blue;
    }
  }
}

// =============>>>>>>> Dashboard Table <<<<<<<<=============

.dashboard-card-wrapper {
  margin: 2rem 0rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  .table-bordered {
    .table-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      // border: 1px solid $border-line;
      .table-cell {
        padding: 5px;
        border: 1px solid $border-line;
        width: calc(100% / 8);
        min-width: 100px;
        min-height: 40px;
      }
    }

    #blank__thead__col {
      width: 150px;
    }
    .thead-column {
      min-width: 80px;
      text-align: center;
      @include normal-text();
    }
    .weekday-interval {
      text-align: center;
      @include normal-text();
      .compare-rate-btn {
        @include center-div-flex();
        justify-content: space-evenly;
        height: 45px;
        border-radius: $border-radius12px;
        background-color: $pale-blue;
        color: $moderate-blue;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .dashboard__table__td {
      .supplyPercent-date-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .supplyPercent {
          @include normal-text();
          color: #4632ff;
        }
        .calendar-date-wrapper {
          min-width: 50px;
          padding: 0px 5px;
          display: flex;
          justify-content: space-between;
          background-color: #4632ff;
          color: $white-text;
          font-size: 14px;
          height: 21px;
          border-radius: 5px;
        }
      }
      .minPrice {
        padding: 0px 12px;
        background-color: #b3f7b6;
        text-align: center;
        @include normal-text();
        color: #08bb0f;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .up_arrow {
          color: #08bb0f;
          margin-right: 10px;
        }
        .down_arrow {
          color: $strong-red;
          margin-right: 10px;
        }
      }
      .maxPrice {
        padding: 0px 12px;
        background-color: #f7dcdc;
        text-align: center;
        @include normal-text();
        color: #f81607;

        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        .up_arrow {
          color: $dark-green;
          margin-right: 10px;
        }
        .down_arrow {
          color: #f81607;
          margin-right: 10px;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
    .compititors-data-display {
      .minPrice {
        padding: 0px 12px;
        background-color: #b3f7b6;
        text-align: center;
        @include normal-text();
        color: #08bb0f;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .up_arrow {
          color: #08bb0f;
          margin-right: 10px;
        }
        .down_arrow {
          color: $strong-red;
          margin-right: 10px;
        }
      }
      .maxPrice {
        padding: 0px 12px;
        background-color: #f7dcdc;
        text-align: center;
        @include normal-text();
        color: #f81607;

        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        .up_arrow {
          color: $dark-green;
          margin-right: 10px;
        }
        .down_arrow {
          color: #f81607;
          margin-right: 10px;
        }
      }
    }
  }
  .dashboard_legend_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    .dashboard-lable-min-price-color-symbol {
      @include normal-text();
      color: $lime-green;
    }
    .dashboard-lable-max-price-color-symbol {
      @include normal-text();
      color: $soft-red;
    }
  }
}
