// define the font mixin for manrope local font (Fallback)
//mixin-font-face
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: otf woff2 woff ttf) {
    $src: null;
    $extmods: (
            svg: "#" + str-replace($name, " ", "_")
    );
    $formats: (
            ttf: "truetype"
    );
    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }
    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
        font-display: swap;
    }
}

@include font-face("Manrope", "../fonts/light/Manrope-Light", 300, normal, otf woff2 woff ttf);
@include font-face("Manrope", "../fonts/regular/Manrope-Regular", 400, normal, otf woff2 woff ttf);
@include font-face("Manrope", "../fonts/medium/Manrope-Medium", 500, normal, otf woff2 woff ttf);
@include font-face("Manrope", "../fonts/semi-bold/Manrope-SemiBold", 600, normal, otf woff2 woff ttf);
@include font-face("Manrope", "../fonts/bold/Manrope-Bold", 700, normal, otf woff2 woff ttf);
@include font-face("Manrope", "../fonts/extra-bold/Manrope-ExtraBold", 800, normal, otf woff2 woff ttf);