.trend-breadcrumb-toggle-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .trend-table-label {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    text-align: center;
    font-weight: bold;
  }
  .trend-table-label {
    color: $moderate-blue;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    font-family: "Merriweather", serif;
    mask-image: linear-gradient(
      -75deg,
      rgba(0, 0, 0, 0.4) 20%,
      #000 20%,
      rgba(0, 0, 0, 0.4) 70%
    );
    -webkit-mask-image: linear-gradient(
      -75deg,
      rgba(0, 0, 0, 0.4) 20%,
      #000 20%,
      rgba(0, 0, 0, 0.4) 70%
    );
    mask-size: 200%;
    -webkit-mask-size: 200%;
    animation: shine 3s linear infinite;
  }
  @keyframes shine {
    from {
      -webkit-mask-position: 150%;
      mask-position: 150%;
    }
    to {
      -webkit-mask-position: -50%;
      mask-position: -50%;
    }
  }

  .graph-calender-tab-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bread-crumbs {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      padding: 8px;

      .bread-crumbs-primary {
        font-family: Arial, Helvetica, sans-serif;
        color: #0a2df3;
        font-weight: 700;
      }

      .bread-crumbs-seconadry {
        font-family: Arial, Helvetica, sans-serif;
        color: rgb(43, 43, 43);
        font-weight: 700;
      }

      .bi-chevron-right {
        font-weight: 900;
        font-size: 14px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .graph-calender-toggle {
      align-self: flex-end;

      .nav-tabs {
        width: fit-content;
        margin: 0;
        padding: 0.3rem;
        border-radius: 12px;
        background-color: #f2f4ff;
        box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
          inset 0px 2px 4px rgba(97, 97, 97, 0.2);

        .nav-link {
          // @include normal-text();
          min-width: 60px !important;
          width: 30px !important;
          color: #223189;
          font-weight: 600;
          text-align: center;
          margin: 0 0.2rem;
          border: 0;

          &:hover {
            border: 0;
            cursor: pointer;
          }

          &:active {
            border: 0;
          }
        }

        .nav-link.active {
          border: 0;
          background-color: #fff;
          border-radius: 12px;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
        }
      }
    }
  }

  .trend-tab-wrapper {
    display: flex;
    // width: 100%;
    height: 50px;
    justify-content: flex-end;
    align-items: center;
    // gap: 1rem;
    margin: 1rem 0rem;

    .calender-view-tab {
      padding: 8px;

      border: 3px solid #0a2df3;

      font-family: "Times New Roman", Times, serif;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .graph-view-tab {
      padding: 8px;
      border: 3px solid #0a2df3;
      border-bottom-right-radius: 8px;
      font-family: "Times New Roman", Times, serif;
      border-top-right-radius: 8px;
    }

    .active-tab {
      background-color: #0a2df3;
      color: #fff;
      border: 0;
    }

    .graph-calender-toggle {
      align-self: flex-end;

      .nav-tabs {
        width: fit-content;
        margin: 0;
        padding: 0.3rem;
        border-radius: 12px;
        background-color: #f2f4ff;
        box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
          inset 0px 2px 4px rgba(97, 97, 97, 0.2);

        .nav-link {
          // @include normal-text();
          min-width: 60px;
          color: #223189;
          font-weight: 600;
          text-align: center;
          margin: 0 0.2rem;
          border: 0;

          &:hover {
            border: 0;
          }

          &:active {
            border: 0;
          }
        }

        .nav-link.active {
          border: 0;
          background-color: #fff;
          border-radius: 12px;
          box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
            0px 2px 4px rgba(15, 86, 179, 0.2);
        }
      }
    }

    .calendar__view__wrapper {
      .calendar__view__tab {
        .nav-tabs {
          width: fit-content;
          padding: 0.3rem;
          border-radius: 8px;
          background-color: #f2f4ff;
          box-shadow: inset 0px 1px 2px rgba(97, 97, 97, 0.2),
            inset 0px 2px 4px rgba(97, 97, 97, 0.2);

          .nav-link {
            // @include normal-text();
            min-width: 40px;
            color: #223189;
            font-weight: 600;
            text-align: center;
            padding: 0.33rem 0.75rem;
            margin: 0 0.2rem;
            border: 0;

            &:hover {
              border: 0;
            }

            &:active {
              border: 0;
            }
          }

          .nav-link.active {
            border: 0;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0px 1px 2px rgba(15, 86, 179, 0.2),
              0px 2px 4px rgba(15, 86, 179, 0.2);
          }
        }
      }
    }

    .trend-line-chart {
      height: 100%;
    }
  }
}

.trend-wrapper {
  width: 100%;

  .hr-line {
    height: 2px;
    width: 97%;
    color: black;
  }

  .month-change-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 180px;
    background-color: #223187;

    padding: 8px;
    border-radius: 28px;
    box-shadow: 10px 10px 18px #e0dcdc;

    .next-prev-arrow-wrapper {
      background-color: #fff;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      .arrow-color {
        color: #223187;
        font-size: 20px;
      }
    }

    .disabled-btn {
      display: none;
    }

    .month-change-month-name {
      color: #fff;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: 500;
      font-size: 20px;
    }
  }

  .trend-rates-wrapper {
    display: flex;
    width: 100%;

    .trend-table {
      margin: 1rem 0rem;
      width: 100%;

      .trend-table-head {
        .trend-table-head-row {
          display: flex;
          flex-direction: row;

          .trend-table-heading {
            border: 1px solid #ccc;
            min-width: 11.11%;
            text-align: center;
            padding: 10px;

            .table-label {
              color: #0a2df3;
              font-size: 12px;
              font-weight: 500;
              font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
                "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
            }

            .inclinehr {
              border: 1px solid black;
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

          .trend-date-row {
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .trend-body-data {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            .trend-rate-bydate {
              font-weight: 500;
            }

            .trend-arrow-up {
              color: #06f506;
              font-size: 25px;
            }

            .trend-arrow-down {
              color: red;
              font-size: 25px;
            }

            .trend-arrow-same {
              color: rgb(255, 213, 0);
              font-size: 20px;
            }

            .trend-rate-differenece {
              display: flex;
              flex-direction: row;
              background-color: rgb(249, 246, 246);
              padding: 6px;
              font-size: 12px;
              font-weight: 700;
              border-radius: 20px;
              min-width: 80px;
              justify-content: space-around;
            }
          }

          .trend-col {
            color: #0a2df3;
          }

          .tren-up {
            color: #05d805;
            font-size: 25px;
          }

          .tren-down {
            color: red;
            font-size: 25px;
          }

          .tren-equal {
            color: yellow;
            font-size: 25px;
          }
        }

        .highlight {
          background-color: #eeeef5;
        }
      }
    }
  }
}

// Trend Graph section
.trend__graph__view__wrapper {
  background-color: $white-color;
  border: 1px solid $border-line;
  border-radius: $border-radius8px;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  width: 100%;

  .trend__graph__height {
    height: 500px;

    .trend-graph-tooltip-wrapper {
      background-color: $white-color;
      padding: 9px 12px;
      border: 1px solid $border-line;
      border-radius: $border-radius8px;

      .trend-graph-tooltip-x-axis {
        @include normal-text();
        text-align: center;
      }

      .trend-graph-tooltip-data-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;

        .left-side-trend-data {
          display: flex;
          align-items: center;
        }

        .serieId {
          @include normal-text();
          font-weight: $font-weight500;
        }

        .yFormatted {
          @include normal-text();
          font-weight: $font-weight500;
        }
      }
    }
  }
}
