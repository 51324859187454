.sources-list-card {
  margin: 1rem 0rem;
  .sources-list-card-header {
    display: flex;
    flex-direction: row;
    .checkbox__wrapper {
      padding: 8px;
      max-width: 40px;
      border-right: 2px solid $border-line;
      border-bottom: 2px solid $border-line;
    }
    .sources-head {
      padding: 8px;
      @include normal-text();
      border-bottom: 2px solid $border-line;
      width: 100%;
    }
  }
  .sources-list-card-body {
    display: flex;
    flex-direction: row;
    .checkbox__wrapper {
      padding: 8px;
      max-width: 40px;
      border-right: 2px solid $border-line;
      border-bottom: 2px solid $border-line;
    }
    .sources-head {
      padding: 8px;
      @include sub-text();
      border-bottom: 2px solid $border-line;
      width: 100%;
    }
  }
  .pagination__wrapper {
    margin-top: 1.5rem;
    .paginationBttns {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .previous {
        .previousbtn {
          color: blue !important;
          font-size: 20px;
        }
      }
      .next {
        .nextbtn {
          color: blue !important;
          font-size: 20px;
        }
      }
      .paginationdisabled {
        .previousbtn {
          display: none;
        }
      }

      .paginationactive {
        height: 25px;
        width: 25px;
        font-size: 20px;
        border-radius: 50%;
        background-color: lightskyblue;
        @include center-div-flex();
      }
    }
  }
}
